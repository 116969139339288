import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: #2c2c2c;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  max-width: 100%;
  margin: 0 8rem 2rem;
  border-radius: 8px;

  @media (max-width: 1024px) {
    margin: 0 4rem 2rem;
  }

  @media (max-width: 768px) {
    margin: 0 2rem 2rem;
  }

  @media (max-width: 480px) {
    margin: 0 1rem 2rem;
  }
`;

export const FooterTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const FooterSubtitle = styled.p`
  font-size: 16px;
  color: #bbb;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FAQSection = styled.div`
  flex: 1 1 calc(50% - 20px);
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 20px;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const Answer = styled.p`
  font-size: 16px;
  color: #ccc;
  margin-bottom: 10px;
  max-height: ${({ show }) => (show ? '200px' : '0')};
  overflow: hidden;
  transition: max-height 0.4s ease;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ShowButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ffcc00;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
