import styled from "styled-components";
import Modal from "react-modal";

export const CartContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;

  .table-responsive {
    overflow-x: auto;
  }

  .cart-table {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;

    th,
    td {
      padding: 15px;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #333;
      color: white;
      font-weight: bold;
    }

    img {
      width: 80px;
      height: auto;
      border-radius: 8px;
    }

    .qty-controls {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 4px;
      }

      p {
        margin: 0 10px;
        font-size: 16px;
      }
    }

    .remove-btn {
      color: red;
      cursor: pointer;
      border: none;
      background: none;
    }

    .remove-btn:hover {
      color: darkred;
    }
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .summary-section,
    .cupom-section {
      width: 48%;
      padding: 20px;
      background-color: white;
      border: 1px solid #ddd;
      border-radius: 8px;
    }

    .summary-section h5,
    .cupom-section h5 {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .total-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .btn-finalizar {
      background-color: #4caf50;
      color: white;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      font-size: 18px;
      border-radius: 4px;
      margin-top: 20px;
      text-decoration: none;
    }

    input {
      width: 70%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    button {
      background-color: #cda435;
      color: white;
      border: none;
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 4px;
    }

    .btn-continuar {
      margin-top: 15px;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 10px 20px;
      border-radius: 4px;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      color: #000;
    }

    .cupom-aplicado {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-coupon-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 16px;
}

.remove-coupon-btn:hover {
  color: darkred;
}


    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .summary-section,
      .cupom-section {
        width: 100%;
        margin-bottom: 20px;
      }

      .cart-table th,
      .cart-table td {
        padding: 10px;
      }

      .qty-controls button {
        padding: 5px;
      }
    }

    @media (max-width: 480px) {
      img {
        width: 60px;
        height: auto;
      }

      input {
        width: 100%;
      }

      .btn-finalizar {
        font-size: 16px;
      }

      button {
        font-size: 14px;
        padding: 8px 12px;
      }

      .btn-continuar {
        font-size: 14px;
        padding: 8px 12px;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 700px;
  max-height: 90vh;
  margin: 150px auto;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f9f9f9;
      }

      p {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }

  button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 15px;
    background-color: #cda435;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #b38b30;
    }
  }
`;
