import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  font-size: 1.8rem;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const Tab = styled.div`
  font-size: 1rem;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  color: ${props => (props.active ? "#333" : "#777")};
  border-bottom: ${props => (props.active ? "3px solid #f00" : "none")};
  transition: all 0.3s ease;

  &:hover {
    color: #333;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const MenuLateral = styled.div`
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .menu-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    padding-bottom: 10px;
    position: relative;

    &::before {
      content: "";
      width: 5px;
      height: 50%;
      background-color: #D02626;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-left: 15px;
  }

  p {
    color: #777;
    margin-top: 5px;
    font-size: 1rem;
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  font-weight: bold;
  color: ${props => (props.active ? "#333" : "#777")};
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${props => (props.active ? "0 4px 8px rgba(0, 0, 0, 0.025)" : "none")};
  padding: 10px 0;
  transition: box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin: 10px 0;
  }
`;

export const LogoutButton = styled(MenuItem)`
  color: #f00;

  &:hover {
    color: #c00;
  }

  svg {
    color: #f00;
  }
`;

export const OrdersContainer = styled.div`
  width: 80%;
  padding-left: 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
`;

export const OrderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const OrderCard = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;

export const DetailsButton = styled.button`
  background-color: #BF9000;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-family: 'Inter', sans-serif;
  max-height: 1rem !important

  &:hover {
    background-color: #ec971f;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`;

// Continue com outros estilos...
export const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: #444;
  margin-bottom: 20px;
`;

export const OrderDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const OrderDetails = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProductTitle = styled.h5`
  margin: 0;
  color: #333;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ProductQuantity = styled.p`
  margin: 0;
  color: #777;
`;
