import React, { useEffect, useState } from "react";
import axios from "axios";

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Token de autenticação não encontrado.");
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/banners`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.data && response.data.length > 0) {
          const filteredBanners = response.data.filter((banner) => banner.tipoBANNER === 1);
          setBanners(filteredBanners);
        }
      } catch (error) {
        console.error("Erro ao buscar os banners:", error);
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    if (banners.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [banners]);

  const currentBanner = banners[currentIndex] || {};

  return (
    <>
      <div className="hero border-1 pb-3 position-relative">
        <div className="card bg-dark text-white border-0 mx-3 position-relative">
          <img
            className="card-img img-fluid"
            src={currentBanner.imagemBANNER ? `data:image/jpeg;base64,${currentBanner.imagemBANNER}` : "./assets/main.png.jpg"}
            alt={currentBanner.tituloBANNER || "Banner"}
            height={500}
          />
          <div className="card-img-overlay d-flex align-items-end justify-content-center">
            <div className="indicators">
              {banners.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${index === currentIndex ? "active" : ""}`}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .indicators {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }

        .indicator {
          width: 12px;
          height: 12px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          transition: background-color 0.3s;
        }

        .indicator.active {
          background-color: white;
        }
      `}</style>
    </>
  );
};

export default Home;
