import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
initMercadoPago("TEST-a1a58275-70c3-47b5-865e-0d32e14ecf25");

const CheckoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CheckoutForm = styled.div`
  width: 60%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const OrderSummary = styled.div`
  width: 35%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-height: 400px;

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 20px;
  }
`;

const CardHeader = styled.div`
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const SummaryList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SummaryItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 1.1rem;
`;

const TotalItem = styled(SummaryItem)`
  font-weight: bold;
  font-size: 1.2rem;
`;

const FormLabel = styled.label`
  font-weight: 600;
  margin-bottom: 5px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  }
`;

const PaymentButton = styled.button`
  width: 100%;
  background-color: #28a745;
  color: white;
  padding: 12px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;

  &:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PaymentSection = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
`;

const EmptyCart = () => (
  <div className="container">
    <div className="row">
      <div className="col-md-12 py-5 bg-light text-center">
        <h4 className="p-3 display-5">Seu carrinho está vazio</h4>
        <Link to="/" className="btn btn-outline-dark mx-4">
          <i className="fa fa-arrow-left"></i> Continuar Comprando
        </Link>
      </div>
    </div>
  </div>
);

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [freteCarrinho, setFreteCarrinho] = useState(0);
  const [precoCarrinho, setPrecoCarrinho] = useState(0);
  const [totalCarrinho, setTotalCarrinho] = useState(0);
  const [userData, setUserData] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [preferenceId, setPreferenceId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const carregaEndereco = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("Token não encontrado.");
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.codigo;

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/enderecos/${userId}`,
          { headers }
        );

        setAddresses(response.data || []);
        setLoading(false);

        // Recupera o endereço padrão do carrinho
        const savedCart = JSON.parse(localStorage.getItem("cart"));
        if (savedCart && savedCart[0]) {
          const cartAddress = savedCart[0];
          const enderecoPadrao = response.data.find(
            (address) =>
              address.logradouroENDERECO === cartAddress.ruaCARRINHO &&
              address.numeroENDERECO === cartAddress.numeroCARRINHO &&
              address.cidadeENDERECO === cartAddress.localizacaoCARRINHO
          );
          if (enderecoPadrao) {
            setSelectedAddress(enderecoPadrao.codigoENDERECO);
          }
        }
      } catch (error) {
        console.error("Erro ao carregar os endereços:", error);
        setError("Erro ao carregar os endereços.");
        setLoading(false);
      }
    };

    carregaEndereco();

    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCartItems(parsedCart);
    }

    const savedSubtotal = localStorage.getItem("subtotal");
    const savedTotal = localStorage.getItem("total");

    if (savedSubtotal && savedTotal) {
      setPrecoCarrinho(parseFloat(savedSubtotal));
      setTotalCarrinho(parseFloat(savedTotal));
    }

    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const decodedToken = jwtDecode(authToken);
      setUserData(decodedToken);
    }
  }, []);

  const handleSaveOrder = async () => {
    const token = localStorage.getItem("authToken");
  
    if (!token) {
      setError("Token não encontrado.");
      return;
    }
  
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    if (!storedCart || storedCart.length === 0) {
      alert("O carrinho está vazio ou os dados não foram encontrados.");
      return;
    }
  
    const storedCartItem = storedCart[0];
  
    // Limpar o Base64 das imagens dentro do array de itens
    const cleanItems = storedCartItem.itens.map((item) => {
      const { imagemITEMCARRINHO, ...rest } = item; // Remove imagemITEMCARRINHO
      return rest; // Retorna o item sem a imagem Base64
    });
  
    // Substituir a propriedade itens por cleanItems no objeto
    const cleanCartItem = {
      ...storedCartItem,
      itens: cleanItems,
    };
  
    try {
      // Salva o pedido na API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/carrinhos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cleanCartItem),
      });
  
      if (!response.ok) {
        throw new Error("Erro ao salvar o pedido: " + response.statusText);
      }
  
      const result = await response.json();
  
      const sanitizedDocument = storedCartItem.documentoCARRINHO.replace(
        /\D/g,
        ""
      );
  
      const documentType = sanitizedDocument.length === 11 ? "CPF" : "CNPJ";
  
      const orderData = {
        itens: storedCartItem.itens.map((item) => ({
          nome: `Produto ${item.produtoITEMCARRINHO}`,
          preco: item.precoITEMCARRINHO,
          quantidade: item.quantidadeITEMCARRINHO,
        })),
        payer: {
          email: storedCartItem.emailCARRINHO,
          nome: storedCartItem.nomeCARRINHO,
          sobrenome: storedCartItem.sobrenomeCARRINHO,
          tipoDocumento: documentType,
          numeroDocumento: sanitizedDocument,
        },
        codigoCARRINHO: result.carrinho.codigoCARRINHO,
        totalCARRINHO: storedCartItem.totalCARRINHO,
        ...(storedCartItem.freteCARRINHO > 0 && {
          frete: storedCartItem.freteCARRINHO,
        }),
        ...(storedCartItem.descontoTotal > 0 && {
          desconto: storedCartItem.descontoTotal,
        }),
      };
  
      console.log("Payload enviado para o backend:", orderData);
  
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const raw = JSON.stringify(orderData);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const mercadoPagoResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/mercadopago`,
        requestOptions
      );
  
      if (!mercadoPagoResponse.ok) {
        throw new Error("Erro ao gerar preferência no Mercado Pago.");
      }
  
      const mercadoPagoResult = await mercadoPagoResponse.json();
      console.log(mercadoPagoResult);
      setPreferenceId(mercadoPagoResult.preferenceId);
  
      // Atualiza a coluna preferenciaCARRINHO com preferenceId usando PATCH
      const patchData = {
        preferenciaCARRINHO: mercadoPagoResult.preferenceId,
      };
  
      const patchResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/carrinhos/${result.carrinho.codigoCARRINHO}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(patchData),
        }
      );

      
      localStorage.removeItem("cart");
  
      if (!patchResponse.ok) {

        throw new Error("Erro ao atualizar a preferência do carrinho.");
      }
  
      console.log(`Carrinho ${result.carrinho.codigoCARRINHO} atualizado com sucesso!`);
    } catch (error) {
      console.error("Erro ao processar pedido:", error);
    }
  };

  const atualizarEnderecoCart = (endereco) => {
    const cartData = JSON.parse(localStorage.getItem("cart")) || [];
    if (cartData.length > 0) {
      const cart = cartData[0];

      cart.bairroCARRINHO = endereco.bairroENDERECO;
      cart.cepCARRINHO = endereco.cepENDERECO;
      cart.complementoCARRINHO = endereco.complementoENDERECO;
      cart.numeroCARRINHO = endereco.numeroENDERECO;
      cart.ruaCARRINHO = endereco.logradouroENDERECO;
      cart.cidadeCARRINHO = endereco.cidadeENDERECO;
      cart.estadoCARRINHO = endereco.estadoENDERECO;

      localStorage.setItem("cart", JSON.stringify([cart])); // Atualiza o carrinho no localStorage
    }
  };

  const ShowCheckout = () => {
    const cartData = JSON.parse(localStorage.getItem("cart")) || [];
    const cart = cartData[0] || {}; // Pegamos o primeiro elemento da lista, já que o JSON é um array

    const subtotal = cart.precoCARRINHO || 0;
    const shipping = cart.freteCARRINHO || 0;
    const total = cart.totalCARRINHO || 0;
    const discount = cart.descontoTotal || 0;

    const totalItems = Array.isArray(cart.itens)
      ? cart.itens.reduce((acc, item) => acc + item.quantidadeITEMCARRINHO, 0)
      : 0;

    return (
      <CheckoutContainer>
        <OrderSummary>
          <CardHeader>
            <h5>Resumo do Pedido</h5>
          </CardHeader>
          <SummaryList>
            <SummaryItem>
              Produtos ({totalItems}) <span>R${subtotal.toFixed(2)}</span>
            </SummaryItem>
            <SummaryItem>
              Taxa de Entrega <span>R${shipping.toFixed(2)}</span>
            </SummaryItem>
            <SummaryItem>
              Desconto <span>-R${discount.toFixed(2)}</span>
            </SummaryItem>
            <TotalItem>
              Total <span>R${total.toFixed(2)}</span>
            </TotalItem>
          </SummaryList>
        </OrderSummary>

        <CheckoutForm>
          <CardHeader>
            <h4>Dados de Cobrança</h4>
          </CardHeader>

          <div className="row g-3">
            <div className="col-sm-6 my-1">
              <FormLabel htmlFor="firstName">Primeiro Nome</FormLabel>
              <FormInput
                type="text"
                id="firstName"
                value={userData.nome || ""}
                readOnly
              />
            </div>
            <div className="col-sm-6 my-1">
              <FormLabel htmlFor="lastName">Sobrenome</FormLabel>
              <FormInput
                type="text"
                id="lastName"
                value={userData.sobrenome || ""}
                readOnly
              />
            </div>
            <div className="col-12 my-1">
              <FormLabel htmlFor="email">E-mail</FormLabel>
              <FormInput
                type="email"
                id="email"
                value={userData.email || ""}
                readOnly
              />
            </div>
          </div>

          <CardHeader>
            <h4>Endereço para envio</h4>
          </CardHeader>
          <div className="col-12 my-1">
            <FormLabel htmlFor="addressSelect">Selecionar Endereço</FormLabel>
            <FormInput
              as="select"
              id="addressSelect"
              value={selectedAddress}
              onChange={(e) => {
                const selectedEndereco = addresses.find(
                  (address) =>
                    address.codigoENDERECO === parseInt(e.target.value)
                );
                setSelectedAddress(e.target.value);
                if (selectedEndereco) {
                  atualizarEnderecoCart(selectedEndereco); // Atualiza o endereço no carrinho
                }
              }}
            >
              <option value="">Selecione um endereço</option>
              {addresses.map((address) => (
                <option
                  key={address.codigoENDERECO}
                  value={address.codigoENDERECO}
                >
                  {address.logradouroENDERECO}, {address.numeroENDERECO} -{" "}
                  {address.cidadeENDERECO}
                </option>
              ))}
            </FormInput>
          </div>

          <PaymentSection>
            <p>Todas as transações são seguras e criptografadas.</p>
            <p>Sua compra será finalizada no Mercado Pago</p>
            {preferenceId ? (
              <Wallet
                initialization={{
                  preferenceId: preferenceId, // ID retornado pela API
                }}
                customization={{
                  texts: {
                    action: "Pagar com Mercado Pago", // Texto do botão
                    valueProp: "Suas transações são seguras e criptografadas.", // Texto adicional
                  },
                  visual: {
                    buttonBackground: "blue", // Personalize as cores, se necessário
                    buttonText: "white", // Personalize as cores, se necessário
                  },
                }}
              />
            ) : (
              <PaymentButton onClick={handleSaveOrder}>
                Confirmar Pedido
              </PaymentButton>
            )}
          </PaymentSection>
        </CheckoutForm>
      </CheckoutContainer>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Checkout</h1>
        <hr />
        {cartItems && cartItems.length > 0 ? <ShowCheckout /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
