import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Navbar, Main, Product, Footer } from "../components";
import Banner from "../components/Banner";
import Banner2 from "../components/Banner2";
import Slider from "react-slick";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImportantNotices from "../components/ImportantNotices";
import "bootstrap/dist/css/bootstrap.min.css";
import InputMask from "react-input-mask";

function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authToken") !== null
  );
  const [userData, setUserData] = useState(null); // Armazena dados decodificados do token
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    documento: "",
    email: "",
    telefone: "",
    telegram: "",
    nascimento: "",
    estado: "SP", // Fixo para São Paulo
    cidade: "São Paulo", // Fixo para São Paulo
    senha: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          Swal.fire({
            icon: "success",
            title: "Localização obtida",
            text: `Latitude: ${latitude}, Longitude: ${longitude}`,
          });
          // Opcional: Atualizar o estado ou converter coordenadas em endereço
          setFormData((prevData) => ({
            ...prevData,
            latitude,
            longitude,
          }));
        },
        () =>
          Swal.fire("Erro", "Não foi possível obter sua localização!", "error")
      );
    } else {
      Swal.fire(
        "Não suportado",
        "Geolocalização não é suportada no seu navegador.",
        "info"
      );
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!email || !password) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, preencha todos os campos!",
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      senha: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/usuarios/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Login bem-sucedido",
            text: "Redirecionando para a página inicial...",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            localStorage.setItem("authToken", result.token);
            setIsAuthenticated(true);

            // Decodifica o token para extrair o ID do usuário e outras informações
            const decodedToken = jwtDecode(result.token);
            setUserData(decodedToken); // Armazena os dados do token no estado
            navigate("/");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Erro no login",
            text: "Credenciais incorretas. Tente novamente!",
          });
        }
      })
      .catch((error) => {
        console.error("Erro:", error);
        Swal.fire({
          icon: "error",
          title: "Erro no servidor",
          text: "Houve um erro ao se conectar ao servidor. Tente novamente mais tarde.",
        });
      });
  };

  const handleRegisterChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const currentDate = new Date().toISOString(); // Data atual em formato ISO

    const dataToSend = {
      nome: formData.nome,
      email: formData.email,
      telefone: formData.telefone,
      telegram: formData.telegram,
      nascimento: formData.nascimento,
      senha: formData.senha,
      documento: formData.documento,
      localizacao: "São Paulo",
      nivel: 6, // Fixo
      ativo: false, // Sempre será false
      log: currentDate, // Data e hora atual
      criado: currentDate, // Data e hora atual
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/usuarios/criar`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Usuário registrado",
        text: "Seu cadastro foi enviado! Aguarde a aprovação.",
        confirmButtonText: "Ok",
      });

      // Reseta o formulário
      setFormData({
        nome: "",
        email: "",
        senha: "",
        telefone: "",
        telegram: "",
        nascimento: "",
      });

      setShowRegisterModal(false);
    } catch (error) {
      console.error("Erro ao criar o usuário:", error);

      Swal.fire({
        icon: "error",
        title: "Erro no registro",
        text: "Houve um problema ao registrar o usuário. Tente novamente.",
        confirmButtonText: "Fechar",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/produtos`
        );
        setAllProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    };
    fetchProducts();
  }, []);

  if (!isAuthenticated) {
    return (
      <LoginContainer>
        <Logo src="/assets/logo.png" alt="Logo" />
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Entrar</button>
        </form>

        <RegisterLink>
          Novo aqui?{" "}
          <button onClick={() => setShowRegisterModal(true)}>Registrar</button>
        </RegisterLink>

        <Modal
          show={showRegisterModal}
          onHide={() => setShowRegisterModal(false)}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Registrar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={handleRegisterSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {/* Linha com Nome e Documento */}
              <div style={{ display: "flex", gap: "15px" }}>
                {/* Nome */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="nome"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    id="nome"
                    placeholder="Digite seu nome"
                    value={formData.nome}
                    onChange={handleRegisterChange}
                    style={inputStyle}
                  />
                </div>

                {/* Documento */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="documento"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Documento (CPF ou CNPJ)
                  </label>
                  <InputMask
                    mask="999.999.999-99"
                    value={formData.documento}
                    onChange={handleRegisterChange}
                    id="documento"
                    style={inputStyle}
                  />
                </div>
              </div>

              {/* Linha com E-mail e Telefone */}
              <div style={{ display: "flex", gap: "15px" }}>
                {/* E-mail */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="email"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Digite seu e-mail"
                    value={formData.email}
                    onChange={handleRegisterChange}
                    style={inputStyle}
                  />
                </div>

                {/* Telefone */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="telefone"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Telefone
                  </label>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={formData.telefone}
                    onChange={handleRegisterChange}
                    id="telefone"
                    style={inputStyle}
                  />
                </div>
              </div>

              {/* Linha com Telegram e Nascimento */}
              <div style={{ display: "flex", gap: "15px" }}>
                {/* Telegram */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="telegram"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Telegram
                  </label>
                  <input
                    type="text"
                    id="telegram"
                    placeholder="Digite seu Telegram"
                    value={formData.telegram}
                    onChange={handleRegisterChange}
                    style={inputStyle}
                  />
                </div>

                {/* Data de Nascimento */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="nascimento"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Data de Nascimento
                  </label>
                  <input
                    type="date"
                    id="nascimento"
                    value={formData.nascimento}
                    onChange={handleRegisterChange}
                    style={inputStyle}
                  />
                </div>
              </div>

              {/* Senha */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  htmlFor="senha"
                  style={{ fontSize: "1rem", fontWeight: "500" }}
                >
                  Senha
                </label>
                <input
                  type="password"
                  id="senha"
                  placeholder="Digite sua senha"
                  value={formData.senha}
                  onChange={handleRegisterChange}
                  style={inputStyle}
                />
              </div>

              {/* Botão de Enviar */}
              <button
                type="submit"
                disabled={isSubmitting}
                style={{
                  padding: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  fontSize: "1.1rem",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "20px",
                }}
              >
                {isSubmitting ? "Enviando..." : "Registrar"}
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </LoginContainer>
    );
  }

  return (
    <>
      <Navbar
        setFilteredProducts={setFilteredProducts}
        allProducts={allProducts}
      />
      <Main />
      <Product products={filteredProducts} />
      <BannerContainer>
        <DesktopBanners>
          <Banner style={{ borderRadius: 80 }} />
          <Banner2 />
        </DesktopBanners>

        <MobileBanners>
          <Slider {...settings}>
            <Banner />
            <Banner2 />
          </Slider>
        </MobileBanners>
      </BannerContainer>

      <ImportantNotices />
      <Footer />
    </>
  );
}

export default Home;

// Styled Components
const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

const DesktopBanners = styled.div`
  display: flex;
  gap: 35px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileBanners = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 90%;
    margin-right: 25px;
    margin-left: 25px;
    gap: 10px;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    input {
      margin-bottom: 10px;
      padding: 12px;
      font-size: 1rem;
      border-radius: 5px;
      border: 1px solid #ddd;
      width: 100%;
    }

    button {
      padding: 12px;
      font-size: 1rem;
      border-radius: 5px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
    }

    form {
      width: 100%;

      input {
        font-size: 0.9rem;
      }

      button {
        font-size: 0.9rem;
      }
    }
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const RegisterLink = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;

  button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  input {
    flex: 1;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

const FullWidthInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const RegisterButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 1.1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const inputStyle = {
  padding: "12px",
  fontSize: "1rem",
  borderRadius: "5px",
  border: "1px solid #ddd",
  boxSizing: "border-box",
  marginTop: "5px",
};

const selectStyle = {
  padding: "12px",
  fontSize: "1rem",
  borderRadius: "5px",
  border: "1px solid #ddd",
  marginTop: "5px",
};
