import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { addCart } from "../redux/action";
import { Footer, Navbar } from "../components";
import SensationDescription from "../components/SensationDescription";
import {
  ThumbnailContainer,
  StyledContainer,
  ProductDetails,
  SimilarHeader,
  ProductGrid,
  ProductCard,
  ProductInfo,
} from "../styles/ProductInfoStyle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import styled from "styled-components";

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;

  .quantity-input {
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: #f9f9f9;
    outline: none;
    cursor: default;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    border: none;
    /* background-color: #007bff; */
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #0056b3;
      color: #fff;
    }

    &:active {
      transform: scale(0.95);
    }
  }
`;

const Product = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const dispatch = useDispatch();


  const MAX_THUMBNAILS = 5; // Limite de miniaturas visíveis
const [showMore, setShowMore] = useState(false);

  function generateCartTitle(storeReference, userCode) {
    // Obtém a data e hora atual
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Adiciona zero à esquerda para o mês
    const day = String(now.getDate()).padStart(2, "0"); // Adiciona zero à esquerda para o dia
    const hours = String(now.getHours()).padStart(2, "0"); // Adiciona zero à esquerda para as horas
    const minutes = String(now.getMinutes()).padStart(2, "0"); // Adiciona zero à esquerda para os minutos

    // Gera o código do usuário com 5 zeros à esquerda
    const paddedUserCode = String(userCode).padStart(6, "0");

    // Monta o título
    const title = `${storeReference}${year}${month}${day}${hours}${minutes}${paddedUserCode}`;
    return title;
  }

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const addProduct = (product, endereco, quantity) => {
    try {
      // Recuperar e validar o token
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        toast.error("Token de autenticação não encontrado.");
        return;
      }

      let decodedToken;
      try {
        decodedToken = jwtDecode(authToken);
      } catch (error) {
        toast.error("Erro ao decodificar o token.");
        return;
      }

      const { codigo, nome, sobrenome, telefone, email, documento } =
        decodedToken;

      // Validar produto e endereço
      if (!product || !product.codigoPRODUTO) {
        toast.error("Produto inválido.");
        return;
      }

      if (!endereco || !endereco.referenciaENDERECO) {
        toast.error("Endereço inválido.");
        return;
      }

      // Preparar os dados do produto
      const preco = parseFloat(product.precoPRODUTO || 0);
      const imagem = product.imagemPRODUTO || "";
      const precoAtacado = parseFloat(product.precoAtacadoPRODUTO || 0);
      const quantidadeAtacado = product.quantidadeAtacadoPRODUTO || 0;
      const peso = product.pesoPRODUTO || 0;
      const altura = product.alturaPRODUTO || 0;
      const largura = product.larguraPRODUTO || 0;
      const comprimento = product.comprimentoPRODUTO || 0;
      const codigoProduto = product.codigoPRODUTO;

      // Determinar o preço com base na quantidade
      const precoFinal =
        quantity >= quantidadeAtacado && quantidadeAtacado > 0
          ? precoAtacado
          : preco;

      // Recuperar carrinho do localStorage
      let existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      // Procurar o carrinho do usuário logado
      let userCart = existingCart.find(
        (cart) => cart.usuarioCARRINHO === codigo
      );

      if (!userCart) {
        // Criar novo carrinho para o usuário
        userCart = {
          usuarioCARRINHO: codigo,
          preferenciaCARRINHO: "",
          tituloCARRINHO: generateCartTitle("SPARTACUS", codigo),
          documentoCARRINHO: documento,
          areaTelefoneCARRINHO: "00",
          telefoneCARRINHO: telefone,
          nomeCARRINHO: nome,
          sobrenomeCARRINHO: sobrenome,
          emailCARRINHO: email,
          referenciaexternaCARRINHO: endereco.referenciaENDERECO || "Nenhuma",
          ruaCARRINHO: endereco.logradouroENDERECO || "Rua Exemplo",
          numeroCARRINHO: endereco.numeroENDERECO || "123",
          complementoCARRINHO: endereco.complementoENDERECO || "Apto 45",
          cepCARRINHO: endereco.cepENDERECO || "01000-000",
          bairroCARRINHO: endereco.bairroENDERECO || "Centro",
          localizacaoCARRINHO: endereco.cidadeENDERECO || "São Paulo",
          estadoCARRINHO: endereco.estadoENDERECO || "SP",
          precoCARRINHO: 0,
          freteCARRINHO: 0,
          totalCARRINHO: 0,
          linhaCARRINHO: 1,
          statusCARRINHO: 0,
          nCdServicoCARRINHO: "",
          nVlPesoCARRINHO: 0.1,
          nVlComprimentoCARRINHO: .1,
          nVlAlturaCARRINHO: 0.1,
          nVlDiametroCARRINHO: 0.1,
          linkPagamentoCARRINHO: "https://mercadopago.com/link",
          datacriacaoCARRINHO: new Date().toISOString(),
          funcionarioCARRINHO: 0,
          recebidoCARRINHO: "",
          recebimentoCARRINHO: 0,
          ativoCARRINHO: true,
          itens: [],
        };

        existingCart.push(userCart);
      }

      // Procurar item no carrinho
      const existingItem = userCart.itens.find(
        (item) => item.produtoITEMCARRINHO === codigoProduto
      );

      if (existingItem) {
        // Atualizar quantidade e total do item
        existingItem.quantidadeITEMCARRINHO += quantity;
        existingItem.precoITEMCARRINHO =
          existingItem.quantidadeITEMCARRINHO >= quantidadeAtacado &&
          quantidadeAtacado > 0
            ? precoAtacado
            : preco;
        existingItem.totalITEMCARRINHO =
          existingItem.quantidadeITEMCARRINHO * existingItem.precoITEMCARRINHO;

        // Calcular o desconto para o item
        existingItem.descontoITEMCARRINHO =
          existingItem.quantidadeITEMCARRINHO >= quantidadeAtacado &&
          quantidadeAtacado > 0
            ? (preco - precoAtacado) * existingItem.quantidadeITEMCARRINHO
            : 0;
      } else {
        // Adicionar novo item ao carrinho
        userCart.itens.push({
          produtoITEMCARRINHO: codigoProduto,
          imagemITEMCARRINHO: imagem,
          quantidadeITEMCARRINHO: quantity,
          precoITEMCARRINHO: precoFinal,
          precoAtacadoITEMCARRINHO: precoAtacado,
          quantidadeAtacadoITEMCARRINHO: quantidadeAtacado,
          pesoITEMCARRINHO: peso,
          alturaITEMCARRINHO: altura,
          larguraITEMCARRINHO: largura,
          comprimentoITEMCARRINHO: comprimento,
          totalITEMCARRINHO: precoFinal * quantity,
          descontoITEMCARRINHO:
            quantity >= quantidadeAtacado && quantidadeAtacado > 0
              ? (preco - precoAtacado) * quantity
              : 0,
          cupomITEMCARRINHO: null,
        });
      }

      // Atualizar totais do carrinho
      userCart.precoCARRINHO = userCart.itens.reduce(
        (sum, item) => sum + item.totalITEMCARRINHO,
        0
      );
      userCart.totalCARRINHO = userCart.precoCARRINHO + userCart.freteCARRINHO;

      // Salvar carrinho atualizado no localStorage
      localStorage.setItem("cart", JSON.stringify(existingCart));

      toast.success("Produto adicionado ao carrinho com sucesso!", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Erro ao adicionar ao carrinho:", error);
      toast.error("Erro ao realizar a operação.");
    }
  };

  const carregaEndereco = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setError("Token não encontrado.");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.codigo;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/enderecos/${userId}`,
        { headers }
      );

      const endereco = response.data;
      const enderecoPadrao = endereco.find((item) => item.padraoENDERECO === 1);

      setLoading(false);

      addProduct(product, enderecoPadrao, quantity);
    } catch (error) {
      console.error("Erro ao carregar os endereços:", error);
      setError("Erro ao carregar os endereços.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        setLoading(true);
        setLoading2(true);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/produtos/${id}`
        );
        const data = await response.json();

        if (data && Object.keys(data).length) {
          setProduct(data);

          //console.log(data);

          // Define as miniaturas a partir das imagens adicionais
          const images = data.ImagemProdutos.map(
            (img) => img.pathIMAGEMPRODUTO
          );
          images.unshift(data.imagemPRODUTO); // Adiciona a imagem principal como a primeira

          data.imagensPRODUTO = images;
          setSelectedImage(images[0]);

          const responseAll = await fetch(
            `${process.env.REACT_APP_API_URL}/produtos`
          );
          const allProducts = await responseAll.json();
          const similar = allProducts.filter(
            (item) =>
              item.categoriaPRODUTO === data.categoriaPRODUTO &&
              item.codigoPRODUTO !== data.codigoPRODUTO
          );
          setSimilarProducts(similar);
        } else {
          console.error("Erro ao carregar o produto");
        }

        setLoading(false);
        setLoading2(false);
      } catch (error) {
        console.error("Erro ao buscar os produtos:", error);
        setLoading(false);
        setLoading2(false);
      }
    };

    getProduct();
  }, [id]);

  const Loading = () => (
    <div className="container my-5 py-2">
      <div className="row">
        <div className="col-md-6 py-3">
          <Skeleton height={400} width={400} />
        </div>
        <div className="col-md-6 py-5">
          <Skeleton height={30} width={250} />
          <Skeleton height={90} />
          <Skeleton height={40} width={70} />
          <Skeleton height={50} width={110} />
          <Skeleton height={120} />
          <Skeleton height={40} width={110} inline={true} />
          <Skeleton className="mx-3" height={40} width={110} />
        </div>
      </div>
    </div>
  );

  const ShowProduct = () => (
    <div className="container my-5 py-2">
      <div className="row">
        <div className="col-md-1 col-sm-12 py-3">
          <ThumbnailContainer>
            {product.imagensPRODUTO?.map((img, index) => (
              <img
                key={index}
                className={`img-fluid p-2 ${
                  selectedImageIndex === index ? "selected" : ""
                }`}
                src={img}
                alt={`Miniatura ${index + 1}`}
                width="80px"
                height="80px"
                onClick={() => {
                  setSelectedImage(img);
                  setSelectedImageIndex(index);
                }}
                style={{ cursor: "pointer" }}
              />
            ))}
          </ThumbnailContainer>
        </div>
        <div className="col-md-6 col-sm-12 py-3">
          <img
            className="img-fluid"
            src={selectedImage}
            alt={product.nomePRODUTO}
            width="400px"
            height="400px"
          />
        </div>
        <div className="col-md-5 col-sm-12 py-5">
          <h1 className="display-7">{product.nomePRODUTO}</h1>
          <p className="lead" style={{ fontFamily: "Inter", color: "#807D7E" }}>
            <i className="fa fa-star" style={{ color: "#BF9000" }}></i>
            <i className="fa fa-star" style={{ color: "#BF9000" }}></i>
            <i className="fa fa-star" style={{ color: "#BF9000" }}></i>
            <i className="fa fa-star" style={{ color: "#BF9000" }}></i>
            <i
              className="fa fa-star-half-alt"
              style={{ color: "#BF9000" }}
            ></i>{" "}
            4.5 Avaliação do Produto
          </p>
          <ProductDetails>
            <h3>Sobre este produto</h3>
            <p>{product.descricaoPRODUTO}</p>
            <div className="buttons">
              <QuantityControl>
                <button onClick={handleDecrease} className="btn">
                  -
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="quantity-input"
                />
                <button onClick={handleIncrease} className="btn">
                  +
                </button>
              </QuantityControl>
              <button onClick={carregaEndereco} className="btn btn-success">
                Comprar
              </button>

              <span className="price">
                R${parseFloat(product.precoPRODUTO).toFixed(2)}
              </span>
            </div>
          </ProductDetails>
        </div>
      </div>
      {/* <SensationDescription /> */}
    </div>
  );

  const ShowSimilarProduct = () => (
    <div className="py-4 my-4">
      <SimilarHeader>
        <span className="highlight"></span>
        <h2>Produtos Similares</h2>
      </SimilarHeader>
      <ProductGrid>
        {similarProducts.length > 0 ? (
          similarProducts.map((item) => (
            <ProductCard
              key={item.codigoPRODUTO}
              onClick={() => navigate(`/product/${item.codigoPRODUTO}`)}
            >
              <img
                src={item.imagemPRODUTO}
                alt={item.nomePRODUTO}
                height={250}
                style={{ cursor: "pointer" }}
              />
              <ProductInfo>
                <h3 style={{ cursor: "pointer" }}>{item.nomePRODUTO}</h3>
                <p>R$ {parseFloat(item.precoPRODUTO).toFixed(2)}</p>
              </ProductInfo>
            </ProductCard>
          ))
        ) : (
          <p>Nenhum produto similar encontrado.</p>
        )}
      </ProductGrid>
    </div>
  );

  return (
    <>
      <Navbar />
      <StyledContainer className="container">
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row my-5 py-5">
          <div className="d-none d-md-block">
            {loading2 ? <Loading /> : <ShowSimilarProduct />}
          </div>
        </div>
        <ToastContainer />
      </StyledContainer>
      <Footer />
    </>
  );
};

export default Product;
