import styled from "styled-components";

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 350px;
  max-height: 400px; /* Define a altura máxima */
  overflow-y: auto; /* Ativa a barra de rolagem vertical */
  padding-right: 5px;

  /* Estiliza a barra de rolagem */
  ::-webkit-scrollbar {
    width: 8px;
    display: none;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    display: none;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    display: none;
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledContainer = styled.div`
  font-family: 'Inter', sans-serif;
`;

export const ProductDetails = styled.div`
  margin-top: 20px;
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 25px;
      background-color: #28a745;
      margin-right: 10px;
    }
  }

  p {
    margin-bottom: 20px;
    color: #555;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      background-color: #f0f0f0;
      padding: 8px 15px;
      border-radius: 5px;
      font-weight: bold;
    }
  }

  .btn-success {
    background-color: #28a745;
    padding: 10px 20px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const SimilarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .highlight {
    width: 5px;
    height: 30px;
    background-color: #BF9000;
    margin-right: 10px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

export const ProductCard = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  
  img {
    width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 12px;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export const ProductInfo = styled.div`
  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
  }
`;
