import React, { useEffect, useState, useRef } from "react";
import { Footer, Navbar } from "../components";
import { useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { CartContainer, StyledModal } from "../styles/CartStyle";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const Cart = React.memo(() => {
  const [cartItems, setCartItems] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [freteOptions, setFreteOptions] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const inputRef = useRef(""); // Usando ref para evitar re-renderizações
  const dispatch = useDispatch();

  const isCouponApplied = cartItems.some((cartItem) =>
    cartItem.itens.some((item) => item.cupomITEMCARRINHO)
  );

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCartItems(parsedCart);
  
      if (parsedCart[0]?.nCdServicoCARRINHO) {
        console.log(
          "Transportadora selecionada anteriormente:",
          parsedCart[0]?.nCdServicoCARRINHO
        );
      }
  
      calculateShipping(parsedCart);
    }
  }, []);  

  useEffect(() => {
    let sub = 0;
    let items = 0;

    cartItems.forEach((cartItem) => {
      cartItem.itens.forEach((item) => {
        const preco = parseFloat(item.precoITEMCARRINHO) || 0;
        const quantidade = item.quantidadeITEMCARRINHO || 1;
        sub += preco * quantidade;
        items += quantidade;
      });
    });

    setSubtotal(sub);
    setTotalItems(items);
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  // Função para calcular o frete e abrir o modal com as opções
  const calculateShipping = async (cart = cartItems) => {
    if (!cart || cart.length === 0 || !cart[0]?.itens) {
      console.warn("Carrinho vazio ou não inicializado.");
      return;
    }

    const { cepCARRINHO, itens } = cart[0];
    let totalPeso = 0,
      totalAltura = 0,
      totalLargura = 0,
      totalComprimento = 0,
      totalDiametro = 0;

    itens.forEach((item) => {
      totalPeso +=
        parseFloat(item.pesoITEMCARRINHO || 0) * item.quantidadeITEMCARRINHO;
      totalAltura +=
        parseFloat(item.alturaITEMCARRINHO || 0) * item.quantidadeITEMCARRINHO;
      totalLargura +=
        parseFloat(item.larguraITEMCARRINHO || 0) * item.quantidadeITEMCARRINHO;
      totalComprimento +=
        parseFloat(item.comprimentoITEMCARRINHO || 0) *
        item.quantidadeITEMCARRINHO;
      totalDiametro +=
        parseFloat(item.diametroITEMCARRINHO || 0) *
        item.quantidadeITEMCARRINHO;
    });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/localizacoes/frete`,
        {
          params: {
            cep: cepCARRINHO,
            peso: totalPeso,
            comprimento: totalComprimento,
            altura: totalAltura,
            largura: totalLargura,
            diametro: totalDiametro, // Adicionando o parâmetro de diâmetro
            nCdServicoCARRINHO: cart[0]?.nCdServicoCARRINHO || "", // Nome da transportadora
            nVlPesoCARRINHO: totalPeso,
            nVlComprimentoCARRINHO: totalComprimento,
            nVlAlturaCARRINHO: totalAltura,
            nVlDiametroCARRINHO: totalDiametro,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      console.log("Resposta da API de frete:", response.data);

      const options = Array.isArray(response.data) ? response.data : [];

      if (options.length > 0) {
        setFreteOptions(options); // Atualiza as opções de frete
      } else {
        Swal.fire({
          icon: "info",
          title: "Sem opções de frete",
          text: "Nenhuma opção de frete disponível para o CEP informado.",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      }
    } catch (error) {
      console.error("Erro ao calcular o frete:", error);
      Swal.fire({
        icon: "error",
        title: "Erro no cálculo do frete",
        text: "Erro ao calcular o frete. Tente novamente mais tarde.",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    }
  };

  // Função para abrir o modal ao clicar no botão
  const handleOpenModal = () => {
    if (freteOptions.length > 0) {
      openModal();
    } else {
      calculateShipping(); // Caso as opções ainda não tenham sido carregadas
      openModal();
    }
  };

  // Função para selecionar uma opção de frete e fechar o modal
  const selectShippingOption = (option) => {
    setShipping(option.precoFrete);
  
    // Atualize os dados no cartItems com a transportadora selecionada
    const updatedCartItems = cartItems.map((cartItem) => ({
      ...cartItem,
      nCdServicoCARRINHO: option.transportadora, // Nome da transportadora
      freteCARRINHO: option.precoFrete,          // Preço do frete
      totalCARRINHO: subtotal + option.precoFrete,
    }));
  
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    localStorage.setItem("selectedShipping", JSON.stringify(option));
  
    closeModal();
  };
  

  const addItem = (product) => {
    const updatedCart = cartItems.map((cartItem) => {
      const updatedItens = cartItem.itens.map((item) =>
        item.produtoITEMCARRINHO === product.produtoITEMCARRINHO
          ? {
              ...item,
              quantidadeITEMCARRINHO: item.quantidadeITEMCARRINHO
                ? item.quantidadeITEMCARRINHO + 1
                : 1, // Verifica se quantidade existe
            }
          : item
      );
      return { ...cartItem, itens: updatedItens };
    });

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    dispatch(addCart(product));

    // Recalcular frete e desconto
    calculateShipping(updatedCart);
    recalculateTotalsWithDiscount(updatedCart);
  };

  const recalculateTotalsWithDiscount = (cart) => {
    let sub = 0;
    let desconto = 0;

    cart.forEach((cartItem) => {
      cartItem.itens.forEach((item) => {
        const preco = parseFloat(item.precoITEMCARRINHO) || 0;
        const quantidade = item.quantidadeITEMCARRINHO || 1;
        sub += preco * quantidade;
      });
    });

    const cupomAplicado = cart.find((cartItem) =>
      cartItem.itens.some((item) => item.cupomITEMCARRINHO)
    );

    if (cupomAplicado) {
      const cupom = cupomAplicado.itens.find(
        (item) => item.cupomITEMCARRINHO
      )?.cupomITEMCARRINHO;

      if (cupom) {
        if (cupom.tipoCUPOM === "percentual") {
          desconto = (sub * parseFloat(cupom.valorCUPOM)) / 100;
        } else if (cupom.tipoCUPOM === "inteiro") {
          desconto = parseFloat(cupom.valorCUPOM);
        }
      }
    }

    const novoSubtotal = Math.max(0, sub - desconto);
    const novoTotal = novoSubtotal + shipping;

    setSubtotal(novoSubtotal);
    setTotalItems(cart.reduce((acc, item) => acc + item.itens.length, 0));
    localStorage.setItem("subtotal", novoSubtotal.toFixed(2));
    localStorage.setItem("total", novoTotal.toFixed(2));
  };

  const decrementItem = (product) => {
    const updatedCart = cartItems
      .map((cartItem) => {
        const updatedItens = cartItem.itens
          .map((item) => {
            if (item.produtoITEMCARRINHO === product.produtoITEMCARRINHO) {
              return item.quantidadeITEMCARRINHO &&
                item.quantidadeITEMCARRINHO > 1
                ? {
                    ...item,
                    quantidadeITEMCARRINHO: item.quantidadeITEMCARRINHO - 1,
                  }
                : { ...item, quantidadeITEMCARRINHO: 1 }; // Se a quantidade for 1 ou indefinida, garante que seja 1
            }
            return item;
          })
          .filter(Boolean); // Filtra itens nulos (caso a quantidade seja 1)
        return { ...cartItem, itens: updatedItens };
      })
      .filter((cartItem) => cartItem.itens.length > 0); // Filtra carrinho para remover cartItem vazio

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    dispatch(delCart(product));

    recalculateTotals(updatedCart);
  };

  const recalculateTotals = (cartItems) => {
    let sub = 0;
    let items = 0;

    cartItems.forEach((cartItem) => {
      cartItem.itens.forEach((item) => {
        const preco = parseFloat(item.precoITEMCARRINHO) || 0;
        const quantidade = item.quantidadeITEMCARRINHO || 1;
        sub += preco * quantidade;
        items += quantidade;
      });
    });

    setSubtotal(sub);
    setTotalItems(items);

    // Recalcula o frete após alterações no carrinho
    calculateShipping();
  };

  const removeCoupon = () => {
    // Remove o cupom do carrinho
    const updatedCartItems = cartItems.map((cartItem) => {
      const updatedItens = cartItem.itens.map((item) => ({
        ...item,
        cupomITEMCARRINHO: null,
        descontoITEMCARRINHO: 0, // Remove o desconto aplicado
      }));
      return { ...cartItem, itens: updatedItens };
    });

    setCartItems(updatedCartItems); // Atualiza o estado do carrinho
    localStorage.setItem("cart", JSON.stringify(updatedCartItems)); // Atualiza no localStorage
    setCoupon(""); // Limpa o estado do cupom
  };

  const removeItem = (product) => {
    const updatedCart = cartItems
      .map((cartItem) => {
        const updatedItens = cartItem.itens.filter(
          (item) => item.produtoITEMCARRINHO !== product.produtoITEMCARRINHO
        );
        return { ...cartItem, itens: updatedItens };
      })
      .filter((cartItem) => cartItem.itens.length > 0); // Garante que cartItems não ficam vazios

    // Verifica se o item foi encontrado e removido corretamente
    if (updatedCart.length !== cartItems.length) {
      setCartItems(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      console.log("Carrinho atualizado:", updatedCart);
      dispatch(delCart(product));
    } else {
      console.warn("Produto não encontrado no carrinho para remoção:", product);
    }
  };

  const handleCouponChange = (e) => {
    console.log("Valor digitado:", e.target.value);
    setCoupon(e.target.value);
  };

  const applyCoupon = async () => {
    try {
      if (isCouponApplied) {
        Swal.fire({
          icon: "warning",
          title: "Cupom já Aplicado",
          text: "Remova o cupom atual antes de aplicar outro.",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
        return;
      }

      if (!coupon.trim()) {
        Swal.fire({
          icon: "warning",
          title: "Cupom Inválido",
          text: "Por favor, insira um código de cupom válido.",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cupons/${coupon.trim()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      const validCupom = response.data;

      if (
        validCupom &&
        validCupom.ativoCUPOM === 1 &&
        new Date(validCupom.dataInicioCUPOM) <= new Date() &&
        new Date(validCupom.dataFimCUPOM) >= new Date()
      ) {
        let desconto = 0;

        if (validCupom.tipoCUPOM === "percentual") {
          desconto = (subtotal * parseFloat(validCupom.valorCUPOM)) / 100;
        } else if (validCupom.tipoCUPOM === "inteiro") {
          desconto = parseFloat(validCupom.valorCUPOM);
        }

        const novoSubtotal = Math.max(0, subtotal - desconto);
        const novoTotal = novoSubtotal + shipping;

        setSubtotal(novoSubtotal);

        // Atualiza o estado e o localStorage com o desconto e o novo total
        const updatedCartItems = cartItems.map((cartItem) => ({
          ...cartItem,
          cupomAplicado: validCupom.nomeCUPOM,
          descontoTotal: desconto,
          totalCARRINHO: novoTotal, // Atualiza o totalCARRINHO
        }));

        setCartItems(updatedCartItems);

        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        localStorage.setItem("subtotal", novoSubtotal.toFixed(2));
        localStorage.setItem("total", novoTotal.toFixed(2));

        Swal.fire({
          icon: "success",
          title: "Cupom Aplicado",
          text: `Cupom "${
            validCupom.nomeCUPOM
          }" aplicado com sucesso! Você economizou R$ ${desconto.toFixed(2)}.`,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Cupom Inválido",
          text: "Cupom inválido ou expirado.",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      }
    } catch (error) {
      console.error("Erro ao verificar o cupom:", error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Erro ao verificar o cupom. Tente novamente mais tarde.",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    }
  };

  const EmptyCart = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5 bg-light text-center">
          <h4 className="p-3 display-5">Seu Carrinho está vazio</h4>
          <Link to="/" className="btn btn-outline-dark mx-4">
            <i className="fa fa-arrow-left"></i> Continue Comprando
          </Link>
        </div>
      </div>
    </div>
  );

  const CouponInput = React.memo(({ coupon, setCoupon, applyCoupon }) => {
    return (
      <div className="cupom-section">
        <h5>Cupom de Desconto</h5>
        {isCouponApplied ? (
          <div className="cupom-aplicado">
            <span>
              Cupom já aplicado:{" "}
              {
                cartItems.find((cartItem) =>
                  cartItem.itens.some((item) => item.cupomITEMCARRINHO)
                )?.itens[0]?.cupomITEMCARRINHO
              }
            </span>
            <button
              className="remove-coupon-btn"
              onClick={removeCoupon}
              aria-label="Remover Cupom"
            >
              <FaTrashAlt size={16} />
            </button>
          </div>
        ) : (
          <>
            <input
              ref={inputRef} // Referência para o input
              type="text"
              placeholder="Digite seu código de cupom"
              value={coupon}
              onChange={(e) => handleCouponChange(e)}
              // onFocus={(e) => {
              //   e.target.setSelectionRange(
              //     e.target.value.length,
              //     e.target.value.length
              //   );
              // }}
            />
            <button onClick={applyCoupon}>Aplicar Cupom</button>
          </>
        )}
        <Link to="/" className="btn-continuar" style={{ color: "#000" }}>
          Continuar Comprando
        </Link>
      </div>
    );
  });

  const ShowCart = () => (
    <CartContainer>
      <div className="table-responsive">
        <table className="cart-table">
          <thead>
            <tr>
              <th>Foto do Produto</th>
              <th>Detalhes do Produto</th>
              <th>Preço</th>
              <th>Quantidade</th>
              <th>Frete</th>
              <th>Subtotal</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((cartItem) =>
              cartItem.itens.map((item) => (
                <tr key={item.produtoITEMCARRINHO}>
                  <td>
                    <img
                      src={item.imagemITEMCARRINHO}
                      width="80px"
                      height="80px"
                      alt={`Imagem do produto ${item.produtoITEMCARRINHO}`}
                    />

                    <p>Produto {item.produtoITEMCARRINHO}</p>
                  </td>
                  <td>
                    <p>Produto {item.produtoITEMCARRINHO}</p>
                  </td>
                  <td>R$ {parseFloat(item.precoITEMCARRINHO).toFixed(2)}</td>
                  <td>
                    <div className="qty-controls">
                      <button onClick={() => decrementItem(item)}>-</button>
                      <p>{item.quantidadeITEMCARRINHO}</p>
                      <button onClick={() => addItem(item)}>+</button>
                    </div>
                  </td>
                  <td>FREE</td>
                  <td>
                    R${" "}
                    {(
                      item.quantidadeITEMCARRINHO * item.precoITEMCARRINHO
                    ).toFixed(2)}
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => removeItem(item)}
                      aria-label="Remover"
                    >
                      <FaTrashAlt size={20} />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="bottom-section">
        <div className="summary-section">
          <h5>Resumo</h5>
          <div className="total-row">
            <span>Subtotal:</span>
            <span>R$ {subtotal.toFixed(2)}</span>
          </div>

          <div className="total-row">
            <span>Desconto:</span>
            <span>
              - R${" "}
              {cartItems
                .reduce(
                  (acc, cartItem) => acc + (cartItem.descontoTotal || 0),
                  0
                )
                .toFixed(2)}
            </span>
          </div>

          <div className="total-row">
            <span>Frete:</span>
            <span>
              {shipping > 0 ? (
                <button onClick={handleOpenModal}>
                  R$ {shipping.toFixed(2)}
                </button>
              ) : (
                <button onClick={handleOpenModal}>Calcular Frete</button>
              )}
            </span>
          </div>
          <div className="total-row">
            <strong>Total:</strong>
            <strong>
              R$
              {(
                subtotal +
                shipping -
                cartItems.reduce(
                  (acc, cartItem) => acc + (cartItem.descontoTotal || 0),
                  0
                )
              ).toFixed(2)}
            </strong>
          </div>

          <Link
            to="/checkout"
            className="btn-finalizar"
            onClick={() => {
              localStorage.setItem("subtotal", subtotal);
              localStorage.setItem("total", (subtotal + shipping).toFixed(2));
            }}
          >
            Finalizar compra
          </Link>
        </div>

        <CouponInput
          coupon={coupon}
          //setCoupon={setCoupon}
          applyCoupon={applyCoupon}
          cartItems={cartItems}
          removeCoupon={removeCoupon}
        />
      </div>
      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Opções de Frete"
      >
        <h2>Escolha a Opção de Frete</h2>
        <ul>
          {Array.isArray(freteOptions) &&
            freteOptions
              .filter(
                (option) =>
                  option.precoFrete !== null &&
                  option.precoFrete > 0 &&
                  option.precoFrete !== undefined
              )
              .map((option) => (
                <li
                  key={option.codigoServico}
                  onClick={() => selectShippingOption(option)}
                >
                  <p>Transportadora: {option.transportadora}</p>
                  <p>Serviço: {option.descricaoServico}</p>
                  <p>Preço: R$ {option.precoFrete}</p>
                  <p>Prazo de Entrega: {option.prazoEntrega} dias</p>
                </li>
              ))}
        </ul>
        <button onClick={closeModal}>Fechar</button>
      </StyledModal>
    </CartContainer>
  );

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Carrinho</h1>
        <hr />
        {cartItems.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
});

export default Cart;
