import React, { useEffect, useState } from "react";
import axios from "axios";

const MainOffers = () => {
  const [banner, setBanner] = useState({ imagemBANNER: "", tituloBANNER: "", subtituloBANNER: "" });

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        // Obtendo o token de autenticação do localStorage
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Token de autenticação não encontrado.");
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/banners`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.data && response.data.length > 0) {
          // Filtrar o banner do tipoBANNER === 12
          const bannerData = response.data.find((item) => item.tipoBANNER === 12);

          if (bannerData) {
            setBanner({
              imagemBANNER: bannerData.imagemBANNER,
              tituloBANNER: bannerData.tituloBANNER,
              subtituloBANNER: bannerData.subtituloBANNER,
            });
          } else {
            console.warn("Nenhum banner com tipoBANNER === 12 encontrado.");
          }
        }
      } catch (error) {
        console.error("Erro ao buscar o banner:", error);
      }
    };

    fetchBanner();
  }, []);

  return (
    <div className="hero border-1 pb-3">
      <div className="card bg-dark text-white border-0 mx-3">
        <img
          className="card-img img-fluid"
          src={banner.imagemBANNER ? `data:image/jpeg;base64,${banner.imagemBANNER}` : "./assets/main.png.jpg"}
          alt={banner.tituloBANNER || "Banner"}
          height={500}
        />
        <div className="card-img-overlay d-flex align-items-center">
          {/* Você pode habilitar o texto do banner aqui */}
          {/* <div className="container">
            <h5 className="card-title fs-1 text fw-lighter">{banner.tituloBANNER || "New Season Arrivals"}</h5>
            <p className="card-text fs-5 d-none d-sm-block">
              {banner.subtituloBANNER || "This is a wider card with supporting text below as a natural lead-in to additional content."}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainOffers;
