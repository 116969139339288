import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {
  FooterContainer,
  FooterTitle,
  FooterSubtitle,
  FAQContainer,
  FAQSection,
  Question,
  Answer,
  ShowButton
} from '../styles/FooterFAQStyles';

const FooterFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [showAnswer, setShowAnswer] = useState({});

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        // Obtendo o token de autorização do localStorage
        const authToken = localStorage.getItem('authToken');
        
        // Configurando o cabeçalho de autorização
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/faqs`, config);
        
        // Log para verificar a resposta da API
        console.log("Resposta da API:", response.data);
        
        setFaqs(response.data.filter(faq => faq.ativoFAQ));
        
        // Log para verificar o estado 'faqs' após o filtro
        console.log("Estado FAQs após o filtro:", response.data.filter(faq => faq.ativoFAQ));
      } catch (error) {
        console.error('Erro ao buscar FAQs:', error);
      }
    };

    fetchFAQs();
  }, []);

  const toggleAnswer = (id) => {
    setShowAnswer(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <FooterContainer>
      <FooterTitle>Dúvidas Frequentes</FooterTitle>
      <FooterSubtitle>Informações para usuários</FooterSubtitle>
      <FAQContainer>
        {faqs.map(faq => (
          <FAQSection key={faq.codigoFAQ}>
            <Question>
              <span>{faq.perguntaFAQ}</span>
              <ShowButton onClick={() => toggleAnswer(faq.codigoFAQ)}>
                <FontAwesomeIcon icon={showAnswer[faq.codigoFAQ] ? faMinus : faPlus} />
              </ShowButton>
            </Question>
            <Answer show={showAnswer[faq.codigoFAQ]}>{faq.respostaFAQ}</Answer>
          </FAQSection>
        ))}
      </FAQContainer>
    </FooterContainer>
  );
};

export default FooterFAQ;
