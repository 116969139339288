import React from "react";
import { useLocation } from "react-router-dom";

const SuccessPage = () => {
  const location = useLocation();

  // Extrair parâmetro de consulta da URL
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const paymentId = getQueryParam("payment_id");

  const handleButtonClick = () => {
    window.location.href = "/cart";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f3f4f6",
        margin: "0",
      }}
    >
      <div
        style={{
          textAlign: "center",
          background: "#ffffff",
          padding: "2rem 3rem",
          borderRadius: "10px",
          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)",
          animation: "fadeIn 0.8s ease-in-out",
        }}
      >
        <style>
          {`
            @keyframes fadeIn {
              from {
                opacity: 0;
                transform: scale(0.9);
              }
              to {
                opacity: 1;
                transform: scale(1);
              }
            }
          `}
        </style>
        <h1
          style={{
            fontSize: "2rem",
            color: "#28a745",
            marginBottom: "0.5rem",
          }}
        >
          Compra Realizada com Sucesso!
        </h1>
        <p
          style={{
            fontSize: "1rem",
            color: "#555555",
            marginBottom: "1.5rem",
          }}
        >
          Obrigado por comprar conosco. Seu pedido foi processado com sucesso.
        </p>
        {paymentId ? (
          <p
            style={{
              fontSize: "1rem",
              color: "#555555",
              marginBottom: "1.5rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
              }}
            >
              ID do Pagamento:
            </span>{" "}
            {paymentId}
          </p>
        ) : (
          <p
            style={{
              fontSize: "1rem",
              color: "#555555",
              marginBottom: "1.5rem",
            }}
          >
            Não foi possível identificar o ID do pagamento.
          </p>
        )}
        <button
          style={{
            backgroundColor: "#28a745",
            color: "#ffffff",
            border: "none",
            padding: "0.75rem 1.5rem",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={handleButtonClick}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor = "#218838")
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = "#28a745")
          }
          onMouseDown={(e) => (e.target.style.transform = "scale(0.98)")}
          onMouseUp={(e) => (e.target.style.transform = "scale(1)")}
        >
          Voltar para a Página Inicial
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
