import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// Styled Components
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

const FormSubtitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;
const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  background-color: #28a745;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const Select = styled.select`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
`;

const CancelButton = styled.button`
  background-color: #dc3545;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const AddressList = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const AddressContainer = styled.div`
  margin-top: 20px;
`;

const EditButton = styled.button`
  background-color: #ffc107;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e0a800;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 800px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const MyInformation = () => {
  const [addressData, setAddressData] = useState({
    referenciaENDERECO: "",
    padraoENDERECO: 1,
    cepENDERECO: "",
    logradouroENDERECO: "",
    numeroENDERECO: "",
    complementoENDERECO: "",
    bairroENDERECO: "",
    cidadeENDERECO: "",
    estadoENDERECO: "",
  });
  const [createdAddress, setCreatedAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    carregaEndereco();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  const handleSetDefaultAddress = async (id, isDefault) => {
    try {
      const token = localStorage.getItem("authToken");

      await axios.put(
        `${process.env.REACT_APP_API_URL}/enderecos/${id}`,
        { padraoENDERECO: isDefault ? 0 : 1 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Endereço Atualizado",
        text: isDefault
          ? "Este endereço não é mais o padrão."
          : "Este endereço foi definido como padrão.",
        confirmButtonText: "Ok",
      });

      // Recarrega a lista de endereços para refletir a mudança
      carregaEndereco();
    } catch (error) {
      console.error("Erro ao definir endereço padrão:", error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível atualizar o endereço padrão.",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.codigo;

      const endereco = {
        usuarioENDERECO: userId,
        referenciaENDERECO: addressData.referenciaENDERECO,
        padraoENDERECO: addressData.padraoENDERECO,
        cepENDERECO: addressData.cepENDERECO,
        logradouroENDERECO: addressData.logradouroENDERECO,
        numeroENDERECO: addressData.numeroENDERECO,
        complementoENDERECO: addressData.complementoENDERECO,
        bairroENDERECO: addressData.bairroENDERECO,
        cidadeENDERECO: addressData.cidadeENDERECO,
        estadoENDERECO: addressData.estadoENDERECO,
        ativoENDERECO: 1,
      };

      const response = editing
        ? await axios.put(
            `${process.env.REACT_APP_API_URL}/enderecos/${editAddressId}`,
            endereco,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
        : await axios.post(
            `${process.env.REACT_APP_API_URL}/enderecos`,
            endereco,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

      setCreatedAddress(response.data);
      setEditing(false);
      setEditAddressId(null);
      setIsModalOpen(false);

      Swal.fire({
        icon: "success",
        title: "Endereço salvo!",
        text: editing
          ? "O endereço foi atualizado com sucesso."
          : "O endereço foi salvo com sucesso.",
        confirmButtonText: "Ok",
      });

      carregaEndereco();
    } catch (error) {
      console.error("Erro ao salvar endereço:", error);

      Swal.fire({
        icon: "error",
        title: "Erro ao salvar",
        text: "Ocorreu um erro ao salvar o endereço. Por favor, tente novamente.",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleEdit = (address) => {
    setEditing(true);
    setEditAddressId(address.codigoENDERECO);
    setAddressData({
      referenciaENDERECO: address.referenciaENDERECO,
      cepENDERECO: address.cepENDERECO,
      logradouroENDERECO: address.logradouroENDERECO,
      numeroENDERECO: address.numeroENDERECO,
      complementoENDERECO: address.complementoENDERECO,
      bairroENDERECO: address.bairroENDERECO,
      cidadeENDERECO: address.cidadeENDERECO,
      estadoENDERECO: address.estadoENDERECO,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditing(false);
    setEditAddressId(null);
  };

  const carregaEndereco = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setError("Token não encontrado.");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.codigo;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/enderecos?usuario=${userId}`,
        { headers }
      );
      setAddresses(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao carregar os endereços:", error);
      setError("Erro ao carregar os endereços.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Token não encontrado",
        text: "Por favor, faça login novamente.",
        confirmButtonText: "Ok",
      });
      return;
    }

    try {
      const confirmDelete = await Swal.fire({
        title: "Tem certeza?",
        text: "Você deseja realmente excluir este endereço? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      if (confirmDelete.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/enderecos/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        Swal.fire({
          icon: "success",
          title: "Excluído!",
          text: "O endereço foi excluído com sucesso.",
          confirmButtonText: "Ok",
        });

        carregaEndereco(); // Recarrega os endereços após excluir
      }
    } catch (error) {
      console.error("Erro ao excluir o endereço:", error);

      Swal.fire({
        icon: "error",
        title: "Erro ao excluir",
        text: "Ocorreu um erro ao excluir o endereço. Por favor, tente novamente.",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleCepSearch = async () => {
    const token = localStorage.getItem("authToken");
  
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Token não encontrado",
        text: "Por favor, faça login novamente.",
        confirmButtonText: "Ok",
      });
      return;
    }
  
    if (!addressData.cepENDERECO || addressData.cepENDERECO.length < 8) {
      Swal.fire({
        icon: "warning",
        title: "CEP inválido",
        text: "Por favor, insira um CEP válido.",
        confirmButtonText: "Ok",
      });
      return;
    }
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/enderecos/busca-cep/${addressData.cepENDERECO}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
      console.log("Resposta do busca-cep:", response.data); // Log para inspeção
    
      if (response.data) {
        const { logradouro, bairro, localidade, uf } = response.data;
    
        setAddressData((prevData) => ({
          ...prevData,
          logradouroENDERECO: logradouro || "",
          bairroENDERECO: bairro || "",
          cidadeENDERECO: localidade || "",
          estadoENDERECO: uf || "",
        }));
    
        Swal.fire({
          icon: "success",
          title: "CEP encontrado",
          text: "Os dados do endereço foram preenchidos automaticamente.",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "CEP não encontrado",
          text: "Não foi possível encontrar dados para este CEP.",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
      Swal.fire({
        icon: "error",
        title: "Erro ao buscar o CEP",
        text: "Ocorreu um erro ao buscar os dados do CEP.",
        confirmButtonText: "Ok",
      });
    }
  };
  

  return (
    <FormContainer>
      <FormTitle>Minhas Informações</FormTitle>
      <FormSubtitle>Adicionar endereço</FormSubtitle>
      <InputGroup>
        <Input
          type="text"
          name="referenciaENDERECO"
          placeholder="Referência"
          onChange={handleInputChange}
        />
        <Input
          type="text"
          name="cepENDERECO"
          placeholder="CEP"
          value={addressData.cepENDERECO}
          onChange={handleInputChange}
          onBlur={handleCepSearch}
        />
        <Input
          type="text"
          value={addressData.logradouroENDERECO}
          name="logradouroENDERECO"
          placeholder="Logradouro"
          onChange={handleInputChange}
        />
        <Input
          type="text"
          name="numeroENDERECO"
          placeholder="Número"
          onChange={handleInputChange}
        />
        <Input
          type="text"
          name="complementoENDERECO"
          placeholder="Complemento"
          onChange={handleInputChange}
        />
        <Input
          type="text"
          value={addressData.bairroENDERECO}
          name="bairroENDERECO"
          placeholder="Bairro"
          onChange={handleInputChange}
        />
        <Input
          type="text"
          
          value={addressData.cidadeENDERECO}
          name="cidadeENDERECO"
          placeholder="Cidade"
          onChange={handleInputChange}
        />
        <Select
  name="estadoENDERECO"
  value={addressData.estadoENDERECO}
  onChange={handleInputChange}
>
  <option value="">Selecione o Estado</option>
  <option value="AC">AC - Acre</option>
  <option value="AL">AL - Alagoas</option>
  <option value="AP">AP - Amapá</option>
  <option value="AM">AM - Amazonas</option>
  <option value="BA">BA - Bahia</option>
  <option value="CE">CE - Ceará</option>
  <option value="DF">DF - Distrito Federal</option>
  <option value="ES">ES - Espírito Santo</option>
  <option value="GO">GO - Goiás</option>
  <option value="MA">MA - Maranhão</option>
  <option value="MT">MT - Mato Grosso</option>
  <option value="MS">MS - Mato Grosso do Sul</option>
  <option value="MG">MG - Minas Gerais</option>
  <option value="PA">PA - Pará</option>
  <option value="PB">PB - Paraíba</option>
  <option value="PR">PR - Paraná</option>
  <option value="PE">PE - Pernambuco</option>
  <option value="PI">PI - Piauí</option>
  <option value="RJ">RJ - Rio de Janeiro</option>
  <option value="RN">RN - Rio Grande do Norte</option>
  <option value="RS">RS - Rio Grande do Sul</option>
  <option value="RO">RO - Rondônia</option>
  <option value="RR">RR - Roraima</option>
  <option value="SC">SC - Santa Catarina</option>
  <option value="SP">SP - São Paulo</option>
  <option value="SE">SE - Sergipe</option>
  <option value="TO">TO - Tocantins</option>
</Select>
      </InputGroup>

      <ButtonGroup>
    {/* Botão para POST do endereço */}
    <SaveButton onClick={handleSave}>Salvar</SaveButton>
  </ButtonGroup>

      <AddressContainer>
        <h3>Endereços Salvos</h3>
        {loading && <p>Carregando...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {addresses.length > 0
          ? addresses.map((address) => (
              <AddressList key={address.codigoENDERECO}>
                <p>
                  <strong>Referência:</strong> {address.referenciaENDERECO}{" "}
                  {address.padraoENDERECO ? (
                    <FontAwesomeIcon
                      icon={faStar}
                      color="#FFD700"
                      title="Endereço Padrão"
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      onClick={() =>
                        handleSetDefaultAddress(address.codigoENDERECO, true)
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faStar}
                      color="#ccc"
                      title="Definir como Padrão"
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      onClick={() =>
                        handleSetDefaultAddress(address.codigoENDERECO, false)
                      }
                    />
                  )}
                </p>
                <p>
                  <strong>CEP:</strong> {address.cepENDERECO}
                </p>
                <p>
                  <strong>Logradouro:</strong> {address.logradouroENDERECO}
                </p>
                <p>
                  <strong>Número:</strong> {address.numeroENDERECO}
                </p>
                <p>
                  <strong>Complemento:</strong> {address.complementoENDERECO}
                </p>
                <p>
                  <strong>Bairro:</strong> {address.bairroENDERECO}
                </p>
                <p>
                  <strong>Cidade:</strong> {address.cidadeENDERECO}
                </p>
                <p>
                  <strong>Estado:</strong> {address.estadoENDERECO}
                </p>
                <ButtonGroup>
                  <EditButton onClick={() => handleEdit(address)}>
                    Editar
                  </EditButton>
                  <DeleteButton
                    onClick={() => handleDelete(address.codigoENDERECO)}
                  >
                    Excluir
                  </DeleteButton>
                </ButtonGroup>
              </AddressList>
            ))
          : !loading && <p>Nenhum endereço encontrado.</p>}
      </AddressContainer>

      {isModalOpen && (
        <ModalBackground>
          <ModalContainer>
            <h2>Editar Endereço</h2>
            <InputGroup>
              <Input
                type="text"
                name="referenciaENDERECO"
                placeholder="Referência"
                value={addressData.referenciaENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="cepENDERECO"
                placeholder="CEP"
                value={addressData.cepENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="logradouroENDERECO"
                placeholder="Logradouro"
                value={addressData.logradouroENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="numeroENDERECO"
                placeholder="Número"
                value={addressData.numeroENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="complementoENDERECO"
                placeholder="Complemento"
                value={addressData.complementoENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="bairroENDERECO"
                placeholder="Bairro"
                value={addressData.bairroENDERECO}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="cidadeENDERECO"
                placeholder="Cidade"
                value={addressData.cidadeENDERECO}
                onChange={handleInputChange}
              />
              <select
                name="estadoENDERECO"
                value={addressData.estadoENDERECO}
                onChange={handleInputChange}
              >
                <option value="">Selecione o Estado</option>
                <option value="AC">AC - Acre</option>
                <option value="AL">AL - Alagoas</option>
                <option value="AP">AP - Amapá</option>
                <option value="AM">AM - Amazonas</option>
                <option value="BA">BA - Bahia</option>
                <option value="CE">CE - Ceará</option>
                <option value="DF">DF - Distrito Federal</option>
                <option value="ES">ES - Espírito Santo</option>
                <option value="GO">GO - Goiás</option>
                <option value="MA">MA - Maranhão</option>
                <option value="MT">MT - Mato Grosso</option>
                <option value="MS">MS - Mato Grosso do Sul</option>
                <option value="MG">MG - Minas Gerais</option>
                <option value="PA">PA - Pará</option>
                <option value="PB">PB - Paraíba</option>
                <option value="PR">PR - Paraná</option>
                <option value="PE">PE - Pernambuco</option>
                <option value="PI">PI - Piauí</option>
                <option value="RJ">RJ - Rio de Janeiro</option>
                <option value="RN">RN - Rio Grande do Norte</option>
                <option value="RS">RS - Rio Grande do Sul</option>
                <option value="RO">RO - Rondônia</option>
                <option value="RR">RR - Roraima</option>
                <option value="SC">SC - Santa Catarina</option>
                <option value="SP">SP - São Paulo</option>
                <option value="SE">SE - Sergipe</option>
                <option value="TO">TO - Tocantins</option>
              </select>
            </InputGroup>
            <ButtonGroup>
              <SaveButton onClick={handleSave}>Salvar</SaveButton>
              <CancelButton onClick={handleCloseModal}>Cancelar</CancelButton>
            </ButtonGroup>
          </ModalContainer>
        </ModalBackground>
      )}
    </FormContainer>
  );
};

export default MyInformation;
