import React, { useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import { Footer, Navbar } from "../components";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
  background-color: #f8f9fa;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

const Title = styled.h1`
  text-align: left;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const MenuLateral = styled.div`
  width: 20%;
  background-color: white;
  padding: 20px;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

const MenuItem = styled.div`
  margin: 20px 0;
  font-weight: bold;
  color: #555;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin: 10px 0;
  }
`;

const OrdersContainer = styled.div`
  width: 80%;
  padding-left: 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
`;

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OrderCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductTitle = styled.h5`
  margin: 0;
  color: #333;
`;

const ProductQuantity = styled.p`
  margin: 0;
  color: #777;
`;

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsButton = styled.button`
  background-color: #f0ad4e;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #ec971f;
  }
`;

const UserContainer = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Orders Component
const Orders = ({ orders }) => (
  <>
    <Title>Meus pedidos</Title>
    {orders.length > 0 ? (
      <OrderList>
        {orders.map((order, index) => (
          <OrderCard key={index}>
            <OrderInfo>
              <h5>Pedido #{order.codigoCARRINHO}</h5>
              <p>Data: {new Date(order.datacriacaoCARRINHO).toLocaleDateString()}</p>
              <p>Total: R$ {parseFloat(order.totalCARRINHO).toFixed(2)}</p>
              <p>Status: {order.statusCARRINHO === 0 ? "Completo" : "Cancelado"}</p>
            </OrderInfo>
            <OrderDetails>
              <ProductDetails>
                <ProductTitle>{order.tituloCARRINHO}</ProductTitle>
                <ProductQuantity>Frete: R$ {parseFloat(order.freteCARRINHO).toFixed(2)}</ProductQuantity>
                <ProductQuantity>Desconto: R$ {parseFloat(order.descontoTotalCARRINHO).toFixed(2)}</ProductQuantity>
              </ProductDetails>
            </OrderDetails>
            <DetailsButton>Ver detalhes</DetailsButton>
          </OrderCard>
        ))}
      </OrderList>
    ) : (
      <p className="text-center">Nenhum pedido encontrado.</p>
    )}
  </>
);

// Main Page Component
const AboutPage = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("Usuário não autenticado");

        const decodedToken = jwtDecode(token);
        const codigoUSUARIO = decodedToken.codigoUSUARIO;

        if (!codigoUSUARIO) throw new Error("Código do usuário não encontrado no token");

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/carrinhos?usuarioCARRINHO=${codigoUSUARIO}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Erro ao buscar os pedidos");

        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Erro ao buscar os pedidos:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <>
      <Navbar />
      <Container>
        <UserContainer>
          <MenuLateral>
            <MenuItem>Meus pedidos</MenuItem>
          </MenuLateral>
          <OrdersContainer>
            {loading ? (
              <p>Carregando pedidos...</p>
            ) : error ? (
              <p className="text-center">Erro: {error}</p>
            ) : (
              <Orders orders={orders} />
            )}
          </OrdersContainer>
        </UserContainer>
      </Container>
      <Footer />
    </>
  );
};

export default AboutPage;
