import React from "react";

const PendingPaymentPage = () => {
  const handleButtonClick = () => {
    window.location.href = "/";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff3cd",
        margin: "0",
      }}
    >
      <div
        style={{
          textAlign: "center",
          background: "#ffffff",
          padding: "2rem 3rem",
          borderRadius: "10px",
          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)",
          animation: "fadeIn 0.8s ease-in-out",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            color: "#856404",
            marginBottom: "0.5rem",
          }}
        >
          Pagamento Pendente
        </h1>
        <p
          style={{
            fontSize: "1rem",
            color: "#555555",
            marginBottom: "1.5rem",
          }}
        >
          Seu pagamento está sendo processado. Por favor, aguarde a confirmação.
        </p>
        <p
          style={{
            fontSize: "1rem",
            color: "#555555",
            marginBottom: "1.5rem",
          }}
        >
          Você pode acompanhar o status na sua conta ou entrar em contato com o suporte, se necessário.
        </p>
        <button
          style={{
            backgroundColor: "#ffc107",
            color: "#ffffff",
            border: "none",
            padding: "0.75rem 1.5rem",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={handleButtonClick}
        >
          Voltar para a Página Inicial
        </button>
      </div>
    </div>
  );
};

export default PendingPaymentPage;
