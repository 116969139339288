import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  NoticesContainer,
  Title,
  CardsContainer,
  Card,
  CardTitle,
  CardContent
} from '../styles/ImportantNoticesStyles';

const ImportantNotices = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/notificacoes`, config);
        
        // Log para verificar a resposta da API
        console.log("Resposta da API de notificações:", response.data);
        
        setNotices(response.data);
      } catch (error) {
        console.error('Erro ao buscar notificações:', error);
      }
    };

    fetchNotices();
  }, []);

  return (
    <NoticesContainer>
      <Title>📢 Avisos importantes</Title>
      <CardsContainer>
        {notices.map((notice) => (
          <Card key={notice.codigoNOTIFICACAO}>
            <CardTitle>{notice.tituloNOTIFICACAO}</CardTitle>
            <CardContent>{notice.corpoNOTIFICACAO}</CardContent>
          </Card>
        ))}
      </CardsContainer>
    </NoticesContainer>
  );
};

export default ImportantNotices;
