import React from "react";
import { useLocation } from "react-router-dom";

const FailurePage = () => {
  const location = useLocation();

  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const paymentId = getQueryParam("payment_id");

  const handleButtonClick = () => {
    window.location.href = "/";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f8d7da",
        margin: "0",
      }}
    >
      <div
        style={{
          textAlign: "center",
          background: "#ffffff",
          padding: "2rem 3rem",
          borderRadius: "10px",
          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)",
          animation: "fadeIn 0.8s ease-in-out",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            color: "#dc3545",
            marginBottom: "0.5rem",
          }}
        >
          Falha no Pagamento
        </h1>
        <p
          style={{
            fontSize: "1rem",
            color: "#555555",
            marginBottom: "1.5rem",
          }}
        >
          Ocorreu um problema ao processar o pagamento. Tente novamente.
        </p>
        {paymentId && (
          <p
            style={{
              fontSize: "1rem",
              color: "#555555",
              marginBottom: "1.5rem",
            }}
          >
            <span style={{ fontWeight: "bold", color: "#333" }}>
              ID do Pagamento:
            </span>{" "}
            {paymentId}
          </p>
        )}
        <button
          style={{
            backgroundColor: "#dc3545",
            color: "#ffffff",
            border: "none",
            padding: "0.75rem 1.5rem",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={handleButtonClick}
        >
          Voltar para a Página Inicial
        </button>
      </div>
    </div>
  );
};

export default FailurePage;
