import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal'; // Certifique-se de instalar o pacote react-modal: npm install react-modal
import {jwtDecode} from "jwt-decode";
import { Footer, Navbar } from "../components"; 

import { 
  Container, 
  UserContainer, 
  Title, 
  TabsContainer, 
  Tab, 
  MenuLateral, 
  MenuItem, 
  LogoutButton, 
  OrdersContainer, 
  OrderList, 
  OrderCard, 
  DetailsButton, 
  OrderInfo, 
  OrderDetailsContainer, 
  OrderDetails, 
  ProductDetails, 
  ProductTitle, 
  ProductQuantity 
} from "../styles/ProfileStyles";
import { FaClipboardList, FaUser, FaSignOutAlt } from "react-icons/fa";
import MyInformation from "../components/MyInformations";

const Profile = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("orders");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("todos");
  const [userName, setUserName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(null);


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("Usuário não autenticado");

        const decodedToken = jwtDecode(token);
        const codigoUSUARIO = decodedToken.codigo;
        
        const nomeUSUARIO = decodedToken.nome;
        setUserName(nomeUSUARIO);

        if (!codigoUSUARIO) throw new Error("Usuário inválido");

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/carrinhos?usuarioCARRINHO=${codigoUSUARIO}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Erro ao buscar os pedidos");

        const data = await response.json();
        setOrders(data);
      } catch (err) {
        console.error("Erro ao buscar pedidos:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const filteredOrders = orders.filter((order) => {
    if (activeTab === "todos") return true;
    if (activeTab === "cancelados") return order.statusCARRINHO === 10;
    if (activeTab === "completos") return order.statusCARRINHO === 8;
    if (activeTab === "novo") return order.statusCARRINHO === 3;
    return false;
  });

  function padWithZeros(number, length) {
    return number.toString().padStart(length, '0');
  }

  // Mapeamento de códigos de status para nomes
const statusMap = {
  1: "Pedido Efetuado", // Seu Pedido foi feito
  2: "Aguardando Pagamento", // Processando Pagamento
  3: "Pedido Aceito", // Pedido Aceito
  4: "Em Separação", // Mercadoria Separada
  5: "Aguardando Transporte", // Preparando para Envio
  6: "A Enviar", // Aguardando Motorista
  7: "Em Transporte", // A Caminho
  8: "Entregue", // Pedido Entregue
  9: "Pagamento Recusado", // Pagamento não aceito
  10: "Pedido Cancelado", // Pedido foi cancelado
  11: "Pagamento em Revisão" // Pagamento em processo de revisão
};

// Função para obter o status
function getStatusDescription(statusCARRINHO) {
  return statusMap[statusCARRINHO] || "Status Desconhecido";
}


const openModal = async (codigoCARRINHO) => {
  setIsModalOpen(true);
  setModalLoading(true);
  setModalError(null);

  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("Usuário não autenticado");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/itens-carrinho/${codigoCARRINHO}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) throw new Error("Erro ao buscar itens do carrinho");

    const data = await response.json();
    setModalItems(data);
  } catch (err) {
    console.error("Erro ao buscar itens do carrinho:", err.message);
    setModalError(err.message);
  } finally {
    setModalLoading(false);
  }
};

const closeModal = () => {
  setIsModalOpen(false);
  setModalItems([]);
};

  return (
    <>
      <Navbar />
      <Container>
        <UserContainer>
          <MenuLateral>
            <div className="menu-header">
              <h2>Olá, {userName}</h2>
            </div>
            <MenuItem onClick={() => setActiveSection("orders")} active={activeSection === "orders"}>
              <FaClipboardList />
              Meus pedidos
            </MenuItem>
            <MenuItem onClick={() => setActiveSection("info")} active={activeSection === "info"}>
              <FaUser />
              Minhas Informações
            </MenuItem>
            <LogoutButton onClick={handleLogout}>
              <FaSignOutAlt />
              Sair
            </LogoutButton>
          </MenuLateral>

          <OrdersContainer>
            {loading ? (
              <p>Carregando pedidos...</p>
            ) : error ? (
              <p>Erro: {error}</p>
            ) : activeSection === "orders" ? (
              <>
                <Title>Meus pedidos</Title>
                <TabsContainer>
                  <Tab active={activeTab === "todos"} onClick={() => setActiveTab("todos")}>
                    Todos
                  </Tab>
                  <Tab active={activeTab === "novo"} onClick={() => setActiveTab("novo")}>
                    Novos
                  </Tab>
                  <Tab active={activeTab === "completos"} onClick={() => setActiveTab("completos")}>
                    Completos
                  </Tab>
                  <Tab active={activeTab === "cancelados"} onClick={() => setActiveTab("cancelados")}>
                    Cancelados
                  </Tab>
                </TabsContainer>

                {filteredOrders.length > 0 ? (
                  <OrderList>
                    {filteredOrders.map((order, index) => (
                      <div key={index}>
                        <OrderCard>
                          
                             <strong>Codigo Pedido:  #{padWithZeros(order.codigoCARRINHO, 6)} </strong> #{order.tituloCARRINHO} 
                             
                        </OrderCard>
                        <OrderDetailsContainer>
                          <OrderDetails>
                            <ProductDetails>
                              
                              <ProductQuantity>Data do pedido:  {new Date(order.datacriacaoCARRINHO).toLocaleDateString()}</ProductQuantity>
                              <ProductQuantity>Status:  {getStatusDescription(order.statusCARRINHO)}</ProductQuantity>
                              <hr />
                              <ProductQuantity>Subtotal: R$ {parseFloat(order.precoCARRINHO).toFixed(2)}</ProductQuantity>
                              <ProductQuantity>Frete: R$ {parseFloat(order.freteCARRINHO).toFixed(2)}</ProductQuantity>
                              <ProductQuantity>Desconto: -R$ {parseFloat(order.descontoTotalCARRINHO).toFixed(2)}</ProductQuantity>
                              <ProductQuantity>Total do pedido: R$ {parseFloat(order.totalCARRINHO).toFixed(2)}</ProductQuantity>
                            </ProductDetails>
                          </OrderDetails>
                          <DetailsButton style={{
                            maxHeight: 50
                          }} onClick={() => openModal(order.codigoCARRINHO)} >Ver Itens</DetailsButton> {/* */}
                        </OrderDetailsContainer>
                      </div>
                    ))}
                  </OrderList>
                ) : (
                  <p className="text-center">Nenhum pedido encontrado.</p>
                )}
              </>
            ) : (
              <MyInformation />
            )}
          </OrdersContainer>
        </UserContainer>
      </Container>
      <Footer />
      <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Detalhes do Pedido"
  style={{
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "700px",
      borderRadius: "12px",
      padding: "20px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  }}
>
  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
    <h2 style={{ margin: 0, fontSize: "1.5rem", fontWeight: "bold", color: "#333" }}>Itens do Pedido</h2>
    <button
      onClick={closeModal}
      style={{
        background: "none",
        border: "none",
        fontSize: "1.2rem",
        color: "#888",
        cursor: "pointer",
      }}
    >
      &times;
    </button>
  </div>

  {modalLoading ? (
    <p style={{ textAlign: "center" }}>Carregando itens...</p>
  ) : modalError ? (
    <p style={{ textAlign: "center", color: "red" }}>Erro: {modalError}</p>
  ) : modalItems.length > 0 ? (
    <ul style={{ listStyleType: "none", padding: 0 }}>
      {modalItems.map((item) => (
        <li
          key={item.codigoITEMCARRINHO}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 0",
            borderBottom: "1px solid #eee",
          }}
        >
          {item.produto.imagemPRODUTO && (
            <img
              src={item.produto.imagemPRODUTO}
              alt={`Imagem de ${item.produto.nomePRODUTO}`}
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                borderRadius: "8px",
                marginRight: "10px",
              }}
            />
          )}
          <div style={{ flex: 1 }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "#333" }}>
              {item.produto.nomePRODUTO}
            </p>
            <p style={{ margin: "5px 0", fontSize: "0.9rem", color: "#666" }}>
              Quantidade: {item.quantidadeITEMCARRINHO}
            </p>
            <p style={{ margin: "5px 0", fontSize: "0.9rem", color: "#666" }}>
              Preço: R$ {parseFloat(item.precoITEMCARRINHO).toFixed(2)}
            </p>
          </div>
          <p style={{ fontWeight: "bold", color: "#333" }}>
            Total: R$ {parseFloat(item.totalITEMCARRINHO).toFixed(2)}
          </p>
        </li>
      ))}
    </ul>
  ) : (
    <p style={{ textAlign: "center", color: "#666" }}>Nenhum item encontrado.</p>
  )}
</Modal>


    </>
  );
};

export default Profile;
