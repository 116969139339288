import React, { useEffect, useState } from "react";
import { Footer, Main, Navbar, Product } from "../components";
import styled from "styled-components";
import Banner from "../components/Banner";
import Banner2 from "../components/Banner2";
import ImportantNotices from "../components/ImportantNotices";
import Slider from "react-slick";
import ProductsContinue from "../components/ProductsContinue";
import axios from "axios";
import MainOffers from "../components/mainOffers";

const Products = () => {
  const [blocks, setBlocks] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const fetchBanners = async () => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.warn(
          "Token de autenticação não encontrado. Redirecionando para login."
        );
        setIsAuthenticated(false);
        return;
      }

      setIsAuthenticated(true);

      try {
        const response = await axios.get(
         `${process.env.REACT_APP_API_URL}/banners`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const banners = response.data;

        // Filtrar banners apenas para os tipos 4, 5, 6, 7
        const mappedBlocks = banners
          .filter(
            (banner) =>
              [9, 10, 11].includes(banner.tipoBANNER) && banner.imagemBANNER
          ) // Filtra por tipo e garante que tem imagem
          .map((banner) => {
            let label = "";
            let image = `data:image/jpeg;base64,${banner.imagemBANNER}`;

            if (banner.tipoBANNER === 9) {
              label = "Samsung Promotion";
            } else if (banner.tipoBANNER === 10) {
              label = "Feminino 50%";
            } else if (banner.tipoBANNER === 11) {
              label = "Kids 30%";
            }

            return {
              id: banner.codigoBANNER,
              label,
              image,
            };
          });

        setBlocks(mappedBlocks);
      } catch (error) {
        console.error("Erro ao buscar banners:", error);
      }
    };

    fetchBanners();
  }, []);

  if (!isAuthenticated) {
    return (
      <div>
        <p>
          Você não está autenticado. Por favor, faça login para acessar esta
          página.
        </p>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <MainOffers />
      <Product />

      {/* Banner container */}
      <BannerContainer>
        <DesktopBanners>
          <Banner />
          <Banner2 />
        </DesktopBanners>

        {/* No mobile, exibe os banners em um carrossel */}
        <MobileBanners>
          <Slider {...settings}>
            <Banner />
            <Banner />
          </Slider>
        </MobileBanners>
      </BannerContainer>

      {/* Exibição dos blocos promocionais */}
      <PromoBlockContainer>
        {blocks.map((block) => (
          <PromoBlock key={block.id}>
            <PromoImage src={block.image} alt={block.label} />
            {/* <PromoLabel>{block.label}</PromoLabel> */}
          </PromoBlock>
        ))}
      </PromoBlockContainer>

      <ProductsContinue />
      <ImportantNotices />
      <Footer />
    </>
  );
};

export default Products;

/* Styled Components */

// Contêiner dos blocos promocionais
const PromoBlockContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Três colunas fixas */
  gap: 10px; /* Reduzido para deixar os itens mais próximos */
  justify-items: center;
  padding: 20px; /* Menor espaçamento ao redor */
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Duas colunas em telas menores */
    gap: 10px;
  }
`;

// Cada bloco promocional (contém a imagem, legenda)
const PromoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px; /* Reduzido para aproveitar mais o espaço */
  border-radius: 12px;
  background-color: #fff;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

// Imagem de cada bloco
const PromoImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 350px; /* Aumentado para destacar mais as imagens */
  object-fit: contain;
  border-radius: 8px;

  @media (max-width: 768px) {
    max-height: 300px; /* Ajustado para telas menores */
  }
`;

// Legenda de cada bloco
const PromoLabel = styled.p`
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
`;

// Container dos banners
const BannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permitir quebra de linha */
  justify-content: center;
  gap: 20px; /* Espaçamento entre os banners */
  margin-bottom: 25px;

  @media (max-width: 768px) {
    flex-direction: column; /* Exibir em coluna para dispositivos móveis */
    gap: 10px;
  }
`;

// Banners para desktop
const DesktopBanners = styled.div`
  display: flex;
  gap: 35px;

  @media (max-width: 768px) {
    display: none;
  }
`;

// Carrossel de banners para mobile
const MobileBanners = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 90%;
    margin-right: 25px;
    margin-left: 25px;
    gap: 10px;
  }
`;
